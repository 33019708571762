import React, {useState, useEffect } from 'react';
import Header from '../../../components/Yard/common/Header'
import './selectservice.css';
import { InputNumber, message,} from 'antd';
import { connect } from "react-redux"
import leftArrow from "../../../images/left_arrow.svg"
import API from '../../../api'
import {Routes} from '../../../utils/route'
import { navigate } from 'gatsby-link';
import YardDetails from '../../../components/data-display/card/YardDetails';
import Add from "./comp/Add"
import Head from "../../../components/Yard/header/Header"
const Index = (props) => {
    const id = props.id
    const [state, setState] = useState(null);
    const [selectedService,setSelectedService]=useState(0)
    const {yardFetchFoodList}=API
const [service,setService]=useState([])
const [loading, setLoading] = useState (true);
    useEffect(()=>{
        const callback=(val)=>{
            if(val===true){
                setLoading(false);
                yardFetchFoodList().then((res)=>{
                    setService(res.data.data)
                })
                if(props.yardHandData){ 
                    filterEquipmentDetails()
                }
            }}
          Routes('yard',callback)
    
},[])
const filterEquipmentDetails = () =>{
        
    const filteredEquipment = props.yardHandData?.filter((val,index) =>{
        
        if(val.ticket_ref_id == id){
            return val
        }
    })
    
    if(filteredEquipment && filteredEquipment.length > 0){
        setState(...filteredEquipment)
        message.success({ content: `FETCHED EQUIPMENT DATA`, duration: 2 });
    }
    
}
    const onChangeInput=(e,_service)=>{
        _service.quantity=e
    }
const callbackAdd=(val,_service)=>{
       _service.quantity=val
      const fltr=[...service]
      fltr.map((item)=>{
           if(parseInt(item.id)=== parseInt(_service.id)){
               return item.selected="selected"
           }
       })
       const selectSerLength=fltr.filter((item)=>{
           return item.selected==="selected"
       })
       setSelectedService(selectSerLength.length)
}

  const  onClickNext=()=>{
    const filter=service.filter((item)=>(item.quantity&&item.quantity!==0))
        navigate(`/yard/wash-detail/${props.id}`,{
            state: {service:filter,item:props.location?.state?.item},
          })
    }
    // const service = [{ id: 1, name: 'hot rinse1',serviceCount:3 }, { id: 2, name: 'hot rinse1' }, { id: 3, name: 'hot rinse1' }
    //     , { id: 4, name: 'hot rinse1' }, { id: 5, name: 'hot rinse1' }, { id: 6, name: 'hot rinse1' }, { id: 7, name: 'hot rinse1' }]
        return (
        <div>
            <Head/>
            <div className="equipment">
            <Header status={props.location?.state?.item} headName="select services" LinkName={props.location?.state?.item==="In Wash"?"/yard/home":`/yard/inspection/${props.id}`} />
                {/* <div className="select_top">
                    <img className="back_logo" src={leftArrow} style={{ height: '20px', marginTop: '6px' }} />
                    <h2>Select Service</h2>
                </div> */}
                   {/* <YardDetails  data={state} id={id}/> */}


                <div className="list_container">
                    <div className='wash_food_select_ser_head'><p><span>Note:</span>Add to Select Service</p></div>
                    {service.length!==0&&service.map((_service,i) => {
                            return <div className="list">
                                   <p>{_service['service_name.param_description']}</p>
                                   <Add callbackAdd={(val)=>callbackAdd(val,_service)}/>
                                   {/* <InputNumber onChange={(e)=>onChangeInput(e,_service)} style={{ width: '62px' }} size="small" min={0} max={10} defaultValue={0} /> */}
                                  </div>
                        })}
                    <div className='wash_food_select_ser_footer'>{selectedService} service selected</div>
                    <div className="next_btnc" onClick={()=>onClickNext()}>next</div>
                    <div className='blank_footer_wash_food'></div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    
    yardHandData:state.yardHandData
})
export default connect(mapStateToProps, null)(Index);